export const top_common = () => {

}//top_common

export const top_pc = () => {
   //console.table("top_pc");

   /*
      gsap.timeline({
        scrollTrigger: {
          trigger: ".ourBusinessWrapper .areaA",
          start: "top center",
          markers:  true,
          toggleActions: "play none none reverse"
        },
        defaults: {
          duration: 0.6,
          //ease: "sine.inOut"
        }
      })
      .to(".ourBusinessWrapper .areaA", {
        clipPath: "inset(0 0% 0 0)"
      })
   */

   /////////////
   /////////////OUR BUSINESSアニメーション
   /////////////

   gsap.to('.ourBusinessWrapper .areaA', { //アニメーションしたい要素を指定
      //x: 800, //横に800px動かす
      clipPath: "inset(0 0% 0 0)",
      ease: "sine.inOut",
      duration: 1,
      scrollTrigger: {
         trigger: '.ourBusinessWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //toggleClass: { targets: '.ourBusinessWrapper .areaA', className: "anime" },
         //once: true,//これでtoggleClassは最初の一回だけ発動することになる
         //toggleActions: "play none none reverse",         
         //markers: true,
      }
   });

   gsap.to('.ourBusinessWrapper .areaB .anime', { //アニメーションしたい要素を指定
      transform: 'translateX(0)',
      opacity: 1,
      ease: "sine.inOut",
      duration: 1.2,
      delay: 0.4,
      scrollTrigger: {
         trigger: '.ourBusinessWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //markers: true,
      },
      stagger: {
         from: "start", //左からアニメーション start、center、edges、random、endが指定できる
         amount: 0.8 //0.1秒ズラしてアニメーション
      }
   });


   /////////////
   /////////////GLOBAL NETWORKSアニメーション
   /////////////
   gsap.to('.globalNetWorkWrapper .anime', { //アニメーションしたい要素を指定
      transform: 'translateX(0)',
      opacity: 1,
      ease: "sine.inOut",
      duration: 1,
      delay: 0.3,
      scrollTrigger: {
         trigger: '.globalNetWorkWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //markers: true,
      },
      stagger: {
         from: "start", //左からアニメーション start、center、edges、random、endが指定できる
         amount: 0.3 //0.1秒ズラしてアニメーション
      }
   });

   gsap.to('.globalNetWorkWrapper .imgAnime--01', { //アニメーションしたい要素を指定
      clipPath: "inset(0 0% 0 0)",
      ease: "sine.inOut",
      duration: 0.7,
      scrollTrigger: {
         trigger: '.globalNetWorkWrapper .imgAnime--01',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //markers: true,
      }
   });

   gsap.to('.globalNetWorkWrapper .imgAnime--02', { //アニメーションしたい要素を指定
      clipPath: "inset(0 0 0 0%)",
      ease: "sine.inOut",
      duration: 0.7,
      scrollTrigger: {
         trigger: '.globalNetWorkWrapper .imgAnime--02',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //markers: true,
      }
   });


   gsap.to('.globalNetWorkWrapper .imgAnime--03', { //アニメーションしたい要素を指定
      //clipPath: "inset(0 0% 0 0%)",
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      ease: "sine.inOut",
      duration: 0.7,
      scrollTrigger: {
         trigger: '.globalNetWorkWrapper .imgAnime--03',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //markers: true,
      }
   });

   /////////////
   /////////////PRODUCTSアニメーション
   /////////////
   gsap.to('.productsWrapper .areaA .anime', { //アニメーションしたい要素を指定
      transform: 'translateX(0)',
      opacity: 1,
      ease: "sine.inOut",
      duration: 1,
      //delay: 0.3,
      scrollTrigger: {
         trigger: '.productsWrapper .areaA',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //markers: true,
      },
      stagger: {
         from: "start", //左からアニメーション start、center、edges、random、endが指定できる
         amount: 0.5 //0.1秒ズラしてアニメーション
      }
   });

   gsap.to('.productsWrapper .imgAnime', { //アニメーションしたい要素を指定
      clipPath: "inset(0 0% 0 0)",
      ease: "sine.inOut",
      duration: 1,
      //delay: 0.3,
      scrollTrigger: {
         trigger: '.productsWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //markers: true,
      },
      stagger: {
         from: "start", //左からアニメーション start、center、edges、random、endが指定できる
         amount: 0.7 //0.1秒ズラしてアニメーション
      }
   });

   /////////////
   /////////////COMPANYアニメーション
   /////////////
   gsap.to('.companyWrapper .areaA', { //アニメーションしたい要素を指定
      //x: 800, //横に800px動かす
      clipPath: "inset(0 0 0 0%)",
      ease: "sine.inOut",
      duration: 1,
      scrollTrigger: {
         trigger: '.companyWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //toggleClass: { targets: '.ourBusinessWrapper .areaA', className: "anime" },
         //once: true,//これでtoggleClassは最初の一回だけ発動することになる
         //toggleActions: "play none none reverse",         
         //markers: true,
      }
   });

   gsap.to('.companyWrapper .areaB .anime', { //アニメーションしたい要素を指定
      transform: 'translateX(0)',
      opacity: 1,
      ease: "sine.inOut",
      duration: 1,
      scrollTrigger: {
         trigger: '.companyWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //markers: true,
      },
      stagger: {
         from: "start", //左からアニメーション start、center、edges、random、endが指定できる
         amount: 0.8 //0.1秒ズラしてアニメーション
      }
   });


   /////////////
   /////////////MESSAGEアニメーション
   /////////////

   gsap.to('.messageWrapper .areaA', { //アニメーションしたい要素を指定
      //x: 800, //横に800px動かす
      '--cpInset': "inset(0 0% 0 0)",
      ease: "sine.inOut",
      duration: 1,
      scrollTrigger: {
         trigger: '.messageWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //toggleClass: { targets: '.ourBusinessWrapper .areaA', className: "anime" },
         //once: true,//これでtoggleClassは最初の一回だけ発動することになる
         //toggleActions: "play none none reverse",         
         //markers: true,
      }
   });

   gsap.to('.messageWrapper .areaB', { //アニメーションしたい要素を指定
      clipPath: "inset(0 0% 0 0)",
      ease: "sine.inOut",
      duration: 1,
      delay: 0.3,
      scrollTrigger: {
         trigger: '.messageWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //toggleClass: { targets: '.ourBusinessWrapper .areaA', className: "anime" },
         //once: true,//これでtoggleClassは最初の一回だけ発動することになる
         //toggleActions: "play none none reverse",         
         //markers: true,
      }
   });

   gsap.to('.messageWrapper .areaA .anime', { //アニメーションしたい要素を指定
      transform: 'translateX(0)',
      opacity: 1,
      ease: "sine.inOut",
      duration: 1,
      scrollTrigger: {
         trigger: '.messageWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //markers: true,
      },
      stagger: {
         from: "start", //左からアニメーション start、center、edges、random、endが指定できる
         amount: 0.5 //0.1秒ズラしてアニメーション
      }
   });

   
   /////////////
   /////////////RECRUITアニメーション
   /////////////

   gsap.to('.recruitWrapper .areaA', { //アニメーションしたい要素を指定
      //x: 800, //横に800px動かす
      '--cpInset': "inset(0 0 0 0%)",
      ease: "sine.inOut",
      duration: 1,
      scrollTrigger: {
         trigger: '.recruitWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //toggleClass: { targets: '.ourBusinessWrapper .areaA', className: "anime" },
         //once: true,//これでtoggleClassは最初の一回だけ発動することになる
         //toggleActions: "play none none reverse",         
         //markers: true,
      }
   });

   gsap.to('.recruitWrapper .areaB', { //アニメーションしたい要素を指定
      clipPath: "inset(0 0 0 0%)",
      ease: "sine.inOut",
      duration: 1,
      delay: 0.3,
      scrollTrigger: {
         trigger: '.recruitWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //toggleClass: { targets: '.ourBusinessWrapper .areaA', className: "anime" },
         //once: true,//これでtoggleClassは最初の一回だけ発動することになる
         //toggleActions: "play none none reverse",         
         //markers: true,
      }
   });

   gsap.to('.recruitWrapper .areaA .anime', { //アニメーションしたい要素を指定
      transform: 'translateX(0)',
      opacity: 1,
      ease: "sine.inOut",
      duration: 1,
      scrollTrigger: {
         trigger: '.recruitWrapper',//アニメーションが始まるトリガーとなる要素
         start: 'top center', //アニメーションが始まる位置を指定
         //markers: true,
      },
      stagger: {
         from: "start", //左からアニメーション start、center、edges、random、endが指定できる
         amount: 0.5 //0.1秒ズラしてアニメーション
      }
   });

}

export const top_sp = () => {
   console.table("top_sp");
}