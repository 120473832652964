import * as top from './top';

window.onload = function () {
   //ページの読み込み終わり
   const mediaQueryList = matchMedia('(min-width:720px)');
   mediaQueryList.addListener(onMediaQueryChange);

   function onMediaQueryChange(mediaQueryList) {
      if (mediaQueryList.matches == true) {
         console.table("PC")

         if ($('body').hasClass('company')) {
            $(".globalNavCompany").find(".subNavWrapper").stop(true, false).slideDown(0);
         } else {
            $('.globalNavCompany').hover(
               function () {
                  $(this).find(".subNavWrapper").stop(true, false).slideDown(500);
   
               },
               function () {
                  $(this).find(".subNavWrapper").stop(true, false).slideUp(500);
               }
            );
         }

         if ($('body').hasClass('top')) {
            //top.top_common();
            top.top_pc();
         }

      } else {
         //console.table("SP")
         let menuIconFlag = false;
         $('.spMenuIconWrapper').click(
            function () {
               //$(this).find(".subNavWrapper").stop(true,false).slideDown(500);
               menuIconFlag = !menuIconFlag;
               if (menuIconFlag) {
                  $('.spMenuIconWrapper').addClass('menuIcon--on');
                  $('.globalNavWrapper').addClass('menu--on');
                  $('.spMenuBack').fadeIn()
                  $('body').addClass('nonScrollY');

                  //iOS用スクロールを不可能に。
                  no_scroll();
               } else {
                  $('.spMenuIconWrapper').removeClass('menuIcon--on');
                  $('.globalNavWrapper').removeClass('menu--on');
                  $('.spMenuBack').fadeOut()
                  $('body').removeClass('nonScrollY');
                  //iOS用スクロールを可能に。
                  return_scroll()
               }
            }
         );


         if ($('body').hasClass('top')) {
            top.top_sp();
         }
      }
   }
   onMediaQueryChange(mediaQueryList);

   function no_scroll() {
      // PCでのスクロール禁止
      // document.addEventListener("mousewheel", scroll_control, { passive: false });
      // スマホでのタッチ操作でのスクロール禁止
      document.addEventListener("touchmove", scroll_control, { passive: false });
   }
   // スクロール禁止解除
   function return_scroll() {
      // PCでのスクロール禁止解除
      // document.removeEventListener("mousewheel", scroll_control, { passive: false });
      // スマホでのタッチ操作でのスクロール禁止解除
      document.removeEventListener('touchmove', scroll_control, { passive: false });
   }

   function scroll_control(event) {
      event.preventDefault();
   }

}
